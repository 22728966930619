import { styled } from 'styled-components';
import { ZIndex } from 'styles/style-variables/z-index';

export const TabsSliderContainer = styled.div<{ $innerWidthWithoutScrollBar: number }>`
  position: sticky;
  top: 48px;
  z-index: ${ZIndex.Header};
  width: ${({ $innerWidthWithoutScrollBar }) => $innerWidthWithoutScrollBar || 0}px;
  margin-left: -16px;
`;
