import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { Button } from 'components/ui/Button';

export const TabsContainer = styled.div`
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0 16px;
  }
`;

export const TabButtonsList = styled.ul`
  display: inline-flex;
  gap: 2px;
  width: 100%;
  padding: 0;
  font-size: 0;
  list-style: none;
`;

export const TabButtonsListItem = styled.li`
  & button {
    border-radius: 0;
  }

  &:first-of-type {

    & button {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }

  &:last-of-type {

    & button {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
  }
`;

export const TabButton = styled(Button)`
  gap: 8px;
  padding: 7px 12px;
  font-size: 12px;
  white-space: nowrap;
  color: #272727;
  user-select: none;

  & svg path {
    fill: #272727;
  }

  &:hover,
  &:focus {
    background-color: #eaeaea;
  }

  &:active {
    background-color: #c9c9c9;
  }

  &:disabled {
    background-color: #a1a1a1;
    color: #fff;

    & svg path {
      fill: #fff;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 4px;
    width: 100%;
    font-size: 10px;

    &:hover,
    &:focus {
      background-color: #fff;
    }

    &:active {
      background-color: #c9c9c9;
    }

    &:disabled {
      background-color: #a1a1a1;
      color: #fff;

      & svg path {
        fill: #fff;
      }
    }
  }
`;
