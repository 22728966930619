import { type FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { Shr, SPaymentWrapper } from 'pages/PagePurchase/components/styled';
import { PaymentDetails } from 'pages/PagePurchase/components/ui/PaymentDetails';
import { PaymentOptionBtc } from 'pages/PagePurchase/components/ui/PaymentOptionBtc';
import { TotalAmount } from 'pages/PagePurchase/components/ui/TotalAmount';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import { IInvoiceResponseDataMapped } from 'store/api/cuverse-api/invoice/types/invoice-response.interface';
import { EPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import { selectCart } from 'store/cart-reducer/cart-selectors';
import { openAdditionalTermsPopupAction } from 'store/modals-reducer/modals.reducer';
import { EPaymentCategory, setPaymentCategoryAction } from 'store/payment-reducer/payment.reducer';
import { EPromoCodeCategories } from 'store/promocode-reducer/constants/promocode-categories';
import { setPromoStatusAction } from 'store/promocode-reducer/promocode.reducer';
import { selectPromoStatus } from 'store/promocode-reducer/promocode.selectors';
import { applyPromoThunkAction } from 'store/promocode-reducer/promocode.thunk-actions';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { Discount } from 'components/ui/Discount';
import { PromoCodeArea } from 'components/ui/PromoCodeArea';
import { IPromoCodeAreaValue } from 'components/ui/PromoCodeArea/types/promo-code-area-value.interface';
import { AppRoute } from 'utils/route/app-route';

import * as S from './PaymentSection.styled';

interface IPropsPaymentSection {
  purchaseStep: EPurchaseStep;
  invoiceData: IInvoiceResponseDataMapped | null;
  t: (key: string) => string;
}

export const PaymentSection: FC<IPropsPaymentSection> = ({
  t,
  invoiceData,
  purchaseStep,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { innerWidth } = useInnerWidth();
  const cartData = useSelector(selectCart);
  const btcUsdRate = useSelector(selectBtcUsdRate);
  const promoStatus = useSelector(selectPromoStatus);

  const { cartId, totalCostPrice, totalCosts } = cartData;
  const discount = invoiceData?.discount || 0;

  const isDeskTop = innerWidth >= parseInt(BreakPoint.TabletTop);

  const discountUsd = (discount / 100) * (totalCostPrice + totalCosts);
  const totalAmount = totalCostPrice + totalCosts - discountUsd;

  const handlePaymentSubmit = () => {
    if (invoiceData?.id) {
      dispatch(setPaymentCategoryAction(EPaymentCategory.Cart));
      navigate(AppRoute.Payment(), { replace: true });
    }
  };

  const handlePromoCodeButtonClick = () => dispatch(setPromoStatusAction(EPromoCodeStatus.Form));

  const handlePromoCodeSubmit = ({ promoCode }: IPromoCodeAreaValue) => {
    if (promoStatus === EPromoCodeStatus.Checking) return;
    void dispatch(
      applyPromoThunkAction({
        purchaseId: cartId,
        promoCode,
        category: EPromoCodeCategories.Cart,
      }),
    );
  };

  const handleCloseButtonClick = () => dispatch(setPromoStatusAction(EPromoCodeStatus.Form));

  const handleAdditionalTermsClick = () => dispatch(openAdditionalTermsPopupAction());

  return (
    <SPaymentWrapper $purchaseStep={purchaseStep}>
      <PaymentDetails
        sumUsd={totalCostPrice}
        feesAndTaxes={totalCosts}
        purchaseStep={EPurchaseStep.Checkout}
        t={t}
      />
      <Shr />
      <S.PromoCodeAreaWrapper>
        {promoStatus === EPromoCodeStatus.Accepted && EPurchaseStep.PaidSuccess ? (
          <>
            <PromoCodeArea
              promoStatus={promoStatus}
              handlePromoCodeButtonClick={handlePromoCodeButtonClick}
              handlePromoCodeSubmit={handlePromoCodeSubmit}
              handleCloseButtonClick={handleCloseButtonClick}
              t={t}
            />
          </>
        ) : (
          purchaseStep !== EPurchaseStep.PaidSuccess && (
            <>
              <PromoCodeArea
                promoStatus={promoStatus}
                handlePromoCodeButtonClick={handlePromoCodeButtonClick}
                handlePromoCodeSubmit={handlePromoCodeSubmit}
                handleCloseButtonClick={handleCloseButtonClick}
                t={t}
              />
              {promoStatus === EPromoCodeStatus.Button && isDeskTop && <Shr />}
            </>
          )
        )}

        {promoStatus === EPromoCodeStatus.Accepted && (
          <Discount discount={discount} sumUsd={totalCostPrice} />
        )}
      </S.PromoCodeAreaWrapper>
      <S.TotalWrapper>
        <PaymentOptionBtc t={t} />
        <TotalAmount
          sumUsd={totalAmount}
          sumBtc={totalAmount / btcUsdRate}
          t={t}
          withBtc={isDeskTop}
        />
      </S.TotalWrapper>
      {purchaseStep === EPurchaseStep.Checkout && (
        <S.AgreementForm
          handleAdditionalTermsClick={handleAdditionalTermsClick}
          handlePaymentSubmit={handlePaymentSubmit}
          isSubmitting={false}
          t={t}
        />
      )}
    </SPaymentWrapper>
  );
};
